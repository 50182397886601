import React from "react"
import About from "../component/About"
import Layout from "../component/Layout"

export default function Home() {
  return (
    <Layout>
      <About />
    </Layout>
  )
}
