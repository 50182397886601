import React from 'react'

const About = () => {
    return (
        <>
            <h1>Sara Kempka</h1>
            <p>Witajcie nazywam się Sara. Potrzebuję duże pomocy od osób nie znajomych :)</p>
            <div style={{
                margin: "2rem 0"
            }}>
                <img
                    style={{
                        maxHeight: 400,
                        maxWidth: "100%"
                    }}
                    src="https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/82344934_2482279415328779_2808822699353178112_n.jpg?_nc_cat=102&_nc_sid=8bfeb9&_nc_ohc=zIhjqbSMtdYAX9hrOX8&_nc_ht=scontent-waw1-1.xx&oh=9f469875ac1261a3e55027221e0ec813&oe=5F97C2FA"
                />
            </div>
            <div style={{
                // width: "100%"
            }}>
                <iframe
                    src="https://player.vimeo.com/video/387790939"
                    width="100%"
                    height="360"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen></iframe>
            </div>
        </>
    )
}

export default About;